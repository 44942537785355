import React from "react";
import "./Components.css";

const Background = ({
  backgroundImage,
  
}) => {

  return (
    <div className="background" id="Projects" style={{ backgroundImage }}>
      
    </div>
  );
};

export default Background;
